import * as React from 'react'
import Login from './Login'
import { bool } from 'prop-types'
import { useDispatch } from 'react-redux'
import Spinner from '@/utils/Spinner'
import Text from '@/utils/Text'
import { LOADING } from '@/utils/messages'

function WrkitRedirectSsoLogin ({ ssoOnly }) {
	const dispatch = useDispatch()

	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		const persistLogin = () => {
			dispatch({
				type: 'user/PERSIST_LOGIN'
			})
		}

		const storedLoggedIn = window.localStorage.getItem('loggedIn') === 'true'

		if (storedLoggedIn) {
			persistLogin()
		}
	}, [dispatch])

	const onLoginSuccess = React.useCallback(async () => {
		setLoading(true)
		await dispatch({
			type: 'user/WRKIT_RE_DIRECT'
		})
		setLoading(false)
	}, [dispatch])

	return <div className="w-full h-full">
		<Login ssoOnly={ssoOnly} onLoginSuccess={onLoginSuccess} className={loading ? 'pointer-events-none' : ''} />
		{loading && <div className="absolute z-100 inset-0 flex flex-col items-center justify-center bg-white gap-md">
			<Text className="text-md" style={{ width: 'max-content' }}>{LOADING}</Text>
			<Spinner />
		</div>}
	</div>
}

WrkitRedirectSsoLogin.propTypes = {
	ssoOnly: bool
}

export default WrkitRedirectSsoLogin
